<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="分组" prop="groupId">
                <a-select placeholder="请选择分组"  :options="groupList"  v-model="queryParam.groupId" style="width: 100%" allow-clear>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="用户ID" prop="userNo">
                <a-input v-model="queryParam.userNo" placeholder="请输入用户ID" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="消费时间"  >
                <a-date-picker v-model="queryParam.startTime"    placeholder="请选择消费时间" @change="onChangeS" allow-clear/> 至
                <a-date-picker v-model="queryParam.endTime"  placeholder="请选择消费时间" @change="onChangeE" allow-clear/>
              </a-form-item>
            </a-col>
            <!--            <a-col :md="5" :sm="24">-->
            <!--              <a-form-item label="用户昵称" prop="nickname">-->
            <!--                <a-input v-model="queryParam.nickname" placeholder="请输入用户昵称" allow-clear/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->

            <a-col :md="4" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'left', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:guild:add']">-->
        <!--          <a-icon type="plus" />新增-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:guild:edit']">-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->

        <!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:guild:remove']">-->
        <!--          <a-icon type="delete" />删除-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:guild:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <set-group-form
        ref="setGroupForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >

        <span slot="userIdentity" slot-scope="text, record">
          <dict-tag :options="dict.type['guild_user_identity']" :value="record.userIdentity"/>
        </span>
        <span slot="position" slot-scope="text, record">
          <dict-tag :options="dict.type['guild_user_position']" :value="record.position"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['biz:guild:edit']" />-->
          <!--          <a @click="$refs.setGroupForm.handleUpdate(record, undefined)" v-hasPermi="['biz:guild:edit']">-->
          <!--            <a-icon type="edit" />修改-->
          <!--          </a>-->
           <a @click="$refs.setGroupForm.handleUpdate(record, undefined)" v-hasPermi="['biz:guild:edit']">
                      <a-icon type="edit" />分组
                    </a>
                  <a v-if="record.status == 1"  @click="handleLeaveOffice(record)" v-hasPermi="['biz:guild:edit']">
                      <a-divider type="vertical"  /> <a-icon type="edit" />离职
                  </a>

          <!--          <a-divider type="vertical" v-hasPermi="['biz:guild:remove']" />-->
          <!--          <a @click="handleDelete(record)" v-hasPermi="['biz:guild:remove']">-->
          <!--            <a-icon type="delete" />删除-->
          <!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getUserAccountBillQueryPage } from '@/api/biz/accountBill'
import {getGroupList} from '@/api/biz/guildUser'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import SetGroupForm from '@/views/biz/guildUser/modules/SetGroupForm.vue'

export default {
  name: 'UserConsumeQuery',
  components: {
    SetGroupForm,
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['guild_user_identity', 'guild_user_position'],
  data () {
    return {
      list: [],
      groupList: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        guildId: null,
        userId: null,
        userIdentity: null,
        position: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '分组名称',
          dataIndex: 'groupName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '推广用户ID',
          dataIndex: 'parentUserNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '推广用户昵称',
          dataIndex: 'parentNickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户ID',
          dataIndex: 'userNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'registerTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收礼主播ID',
          dataIndex: 'toUserNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收礼主播昵称',
          dataIndex: 'toNickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '消费时间',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.initGroupList()
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    //分组列表
    initGroupList(){
      getGroupList({}).then(response => {
        for(let i=0;i<response.data.length;i++){
          let params = {
            value:response.data[i].id,
            label:response.data[i].groupName
          }
          this.groupList.push(params);
        }
      });
    },
    //时间选择
    onChangeS (value, dateString) {
      this.queryParam.startTime = dateString;
      console.log('Formatted Selected Time: ', dateString)
    },
    onChangeE (value, dateString) {
      this.queryParam.endTime = dateString;
      console.log('Formatted Selected Time: ', dateString)
    },

    /** 查询公会用户列表 */
    getList () {
      this.loading = true
      if(this.queryParam.startTime!=null&&this.queryParam.startTime!=''&&this.queryParam.startTime.length==10){
        this.queryParam.startTime = this.queryParam.startTime+" 00:00:00";
      }
      if(this.queryParam.endTime!=null&&this.queryParam.endTime!=''&&this.queryParam.endTime.length==10){
        this.queryParam.endTime = this.queryParam.endTime+" 23:59:59";
      }
      getUserAccountBillQueryPage(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        guildId: undefined,
        userId: undefined,
        userIdentity: undefined,
        position: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 离职按钮操作 */
    handleLeaveOffice (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认将该成员改为离职状态吗?',
        content: '当前选中编号为' + row.nickname + '的数据',
        onOk () {
          let data = {id:row.id}
          return leaveOffice(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGuild(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/guildUser/export', {
            ...that.queryParam
          }, `公会用户_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
